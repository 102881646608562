<template>
  <div class="sidebar">
    <v-card>
      <v-card-title class="headline grey lighten-4" v-if="dialog"
        >Check Out Details</v-card-title
      >
      <v-card-text class="">
        <div v-if="dialog">
          <v-form @submit.prevent="save" method="post" id="checkout-form">
            <v-simple-table class="mb-5 no-hover">
              <tbody>
                <tr>
                  <td colspan="2" class="pt-4 pb-4">
                    <label class="pt-4 pb-4">Description</label><br /><br />
                    <tiptap v-model="fields.description" class="mb-8" />
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="pt-4 pb-4">
                    <label class="pt-4 pb-4">Due Date</label><br /><br />
                    <v-text-field
                      v-model="fields.due_date"
                      type="date"
                      outlined
                      background-color="white"
                      :error="errors.hasOwnProperty('due_date')"
                      :error-messages="errors['due_date']"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="pt-4 pb-4">
                    <label class="pt-4 pb-4">Assignee</label><br /><br />
                    <v-select
                      v-model="fields.assignee"
                      :items="users"
                      item-value="id"
                      item-text="full_name"
                      outlined
                      background-color="white"
                      :error="errors.hasOwnProperty('assignee')"
                      :error-messages="errors['assignee']"
                    ></v-select>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-form>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="accent"
              text
              type="submit"
              :loading="loading"
              form="checkout-form"
              >Save</v-btn
            >
          </v-card-actions>
        </div>
        <div v-else>Select a checkout to see information</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
//import checkoutDialog from "./checkoutDialog.vue";
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
    // checkoutDialog,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      checkout: {},
      fields: {
        description: null,
        due_date: null,
      },
      errors: {},
    };
  },

  computed: {
    users() {
      return this.$store.state.users["users"];
    },
  },

  methods: {
    openForm(checkout) {
      const appId = this.$route.params.id;
      const checkoutId = checkout.id;

      this.isEditing = true;

      this.$store.dispatch("sbpm/checkoutStore/loadCheckout", {
        appId,
        checkoutId,
      });

      this.fields.assignee = checkout.assigne_user
        ? checkout.assigne_user.id
        : null;

      this.checkout = checkout;

      this.fields.description = checkout.description;
      this.fields.due_date = checkout.due_date;
      this.fields.status = checkout.status;

      this.dialog = true;
    },
    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.checkoutId = this.checkout.id;
      }

      this.$store
        .dispatch("sbpm/checkoutStore/saveCheckOut", payload)
        .then(() => {
          if (this.isEditing) {
            this.$store.commit("showGlobalSnackbar", {
              open: true,
              text: "Checkout Successfully updated",
              color: "success",
            });
          }
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.no-hover tr:hover {
  background: transparent !important;
}
</style>
